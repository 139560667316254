import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import '../assets/scss/components/_rigthBanner.scss'; 

const RigthBanner = () => {
  return (
    <a href="https://www.quranplan.com/" target="_blank" className="RigthBanner-cont" rel="noopener noreferrer">
      <StaticImage
        src="../assets/images/quranplan-side.png"
        alt="banner"
        layout="fixed"
        width={260} // Image Size 260px x 300px
        height={300} 
      />
    </a>
  );
};

export default RigthBanner;
